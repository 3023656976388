<template>
    <div>
        <div id="login_container" class="card radius">
            <h3 id="login_text" class="card_title dark padding_small">Registrieren</h3>
            <br>
            <form v-on:keyup.enter="login" class="padding">
                <input class="padding_small blue textspacing" placeholder="Lizenzschlüssel" type="license" v-model="license">
                <input class="padding_small blue textspacing" placeholder="Benutzername:" type="username" v-model="username">
                <input class="padding_small blue textspacing" placeholder="EMail Adresse:" type="email" v-model="email">
                <input class="padding_small blue textspacing" placeholder="Passwort:" type="password" v-model="password">
                <input class="padding_small blue textspacing" placeholder="Passwort:" type="password" v-model="password2">
                <p>{{message}}</p>
                <button id="login_submit" class="blue padding_small" type="button" @click="register">Registrieren</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "register",
    data() {
        return {
            license: document.location.href.match(/#(\d|-|\w)+$/)[0].replace('#', ''),
            username: "",
            email: "",
            password: "",
            password2: "",
            message: ""
        }
    },
    methods: {
        register() {
            if (this.password != this.password2) {
                this.message = "die Passwörter stimmen nicht überein."   
                return
            }
            if (!this.username) {
                this.message = "Bitte geben Sie einen Nutzernamen ein."   
                return
            }
            const formData = new FormData();
            formData.append('method', "register");
            formData.append('license', this.license);
            formData.append('username', this.username);
            formData.append('email', this.email);
            formData.append('password', this.password);
            this.message = "Anmelden..."
            fetch(this.$store.getters.path_account, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                if (result[0] === 1) {
                    this.$router.push("/")
                }
                else {
                    this.message = result[1]
                }
            })
        }
    },
}
</script>

<style scoped>

    body{
        background-color: #22252D;
    }
    input{
        outline: none;
        border: none;
        font-size: 125%;
    }
    #login_container{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    #login_submit{
        width: fit-content;
        margin: auto;
        font-size: 125%;
    }

    form{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
    }

</style>

